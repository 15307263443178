import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="border-b-8 border-[#3366ff] dark:bg-gray-800 dark:border-[#223dbf]">
      <div className="lg:max-w-screen-xl grid items-center justify-center gap-4 px-4 text-center md:px-6 lg:gap-10"></div>
    </footer>
  );
};

export default Footer;
