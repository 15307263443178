import React, { useState } from "react";

import { Label } from "@/components/ui/label";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { Button } from "@/components/ui/button";
import parse from "html-react-parser";
import { cn } from "./utils/cn";
import { motion } from "framer-motion";

import placeholder from "../../web/assets/images.png";

import { Highlight } from "./components/HeroHighlight";

import { fetchTypes } from ".";
import { LayoutGrid } from "./components/LayoutGrid";
import { InfiniteMovingCards } from "./components/InfiniteMovingCards";
import ContactForm from "./ContactForm";
import { useTheme } from "../components/ui/theme-provider";
import { CardStack } from "./components/CardStack";
import { HoverEffect } from "./components/CardHover";

export type Card = {
  id: number;
  name: string;
  designation: string;
  content: React.ReactNode;
};

const Body = ({ response }) => {
  const { theme } = useTheme();
  const [projectCards, setProjectCards] = useState<Card[]>(
    response.data.categories
      .filter((category) => category.groupHandle === "projects")
      .map((project, index) => ({
        id: index + 1,
        name: project.title,
        content: (
          <div className="flex flex-wrap gap-2 md:place-content-center mx-2 md:m-auto">
            {project.gallery.map((image) => parse(image.img))}
          </div>
        ),
      }))
  );

  const cards = response.data.categories
    .filter((category) => category.groupHandle === "services")
    .map((category, index) => {
      let cardClass;
      if (screen.width < 770) {
        cardClass = "col-1";
      } else if (!index) {
        cardClass = "col-1";
      } else if (index === 1 && screen.width > 770) {
        cardClass = "col-3";
      } else if (index === 2) {
        cardClass = "col-2";
      } else {
        cardClass = "col-1";
      }

      return {
        id: index + 1,
        content: (
          <div className="grid gap-1">
            <h3 className="tracking-tighter text-white text-xl">
              {category.description}
            </h3>
          </div>
        ),
        className: cardClass,
        thumbnail: category?.heroImage?.[0]?.url || placeholder,
        title: category.title,
      };
    });

  const testimonials = response.data.categories
    .filter((category) => category.groupHandle === "testimonial")
    .map((category) => ({
      quote: category.description,
      name: category.headline,
      title: category.title,
    }));

  const projects = response.data.categories.filter(
    (category) => category.groupHandle === "projects"
  );

  const prevCard = () => {
    setProjectCards((prevCards: Card[]) => {
      const newArray = [...prevCards];
      const first = newArray.shift();

      newArray.push(first);
      return newArray;
    });
  };

  const nextCard = () => {
    setProjectCards((prevCards: Card[]) => {
      const newArray = [...prevCards]; // create a copy of the array
      newArray.unshift(newArray.pop()!); // move the last element to the front
      return newArray;
    });
  };

  return (
    <main>
      <div className="flex flex-col bg-white dark:bg-gray-800">
        <section className="flex flex-col items-center justify-center gap-4 p-4 md:gap-8 md:p-10 rounded-md">
          <div className="w-full max-w-3xl" id="hero">
            {parse(
              theme === "dark"
                ? response.data.entry.darkModeImage[0].img
                : response.data.entry.heroImage[0].img
            )}
          </div>
          <p className="max-w-prose	text-gray-500 md:text-xl/relaxed lg:text-base/relaxed xl:text-xl/relaxed dark:text-gray-400">
            Providing every customer with the tools that they need to make
            educated decisions about the maintenance and value of their home. We
            have been in the vinyl siding business{" "}
            <Highlight>for more than 35 years and we understand</Highlight> how
            to help people through a critical decision without interfering with
            a personal choice. Hard work is one of our hallmarks and we will
            earn your trust by providing professional installation and quality
            products. But don’t take our word for it, read the reviews yourself.
          </p>
        </section>
        <section className="bg-gray-200 dark:bg-gray-900 rounded-md relative">
          <h2 className="pt-4 px-4 lg:px-0 text-xl font-bold tracking-tighter sm:text-2xl md:text-3xl lg:max-w-screen-xl mx-auto">
            Services
          </h2>
          <div className="h-screen w-full">
            <LayoutGrid cards={cards} />
          </div>
        </section>
        <section className="bg-white flex flex-col px-4 pt-10 lg:p-10 dark:bg-gray-900 rounded-md">
          <div className="lg:max-w-screen-xl flex flex-wrap flex-col gap-4 mx-auto">
            <HoverEffect
              items={[
                {
                  title: "Our Goal",
                  className: screen.width > 770 && "col-span-3",
                  description:
                    "Gelinas Vinyl Siding Company takes great pride in our craftsmanship. Every project receives the same level of care and attention to detail, regardless of job size. Our experienced installers use the highest quality materials, and we guarantee our work! Gelinas Siding & Contracting is committed to our customer’s satisfaction. Our long-standing reputation speaks for itself. Following the completion of our jobs, we always receive positive feedback from our clients and most of our projects exceed the customer’s expectations. We have remained a small business to focus on your individual needs.",
                  link: "2",
                },
                {
                  title: "Vinyl Siding Experts",
                  description:
                    "Gelinas Siding provides every customer with the tools that they need to make educated decisions about the maintenance and value of their home. We have been in the vinyl siding business for more than 35 years and we understand how to help people through a critical decision without interfering with a personal choice. Hard work is one of our hallmarks and we will earn your trust by providing professional installation and quality products. ",
                  link: "0",
                },
                {
                  title: "Our Service Area",
                  description:
                    "Our service area covers most of Rockingham County in Southern New Hampshire, including but not limited to: Atkinson, Chester, Danville, Derry, Hampstead, Londonderry, Pelham, Plaistow, Salem, Sandown and Windham. We also serve some towns in northern Massachusetts area such as Haverhill, Methuen and Lawrence and a few others in Essex County.",
                  link: "1",
                },
                {
                  title: "Why Choose Gelinas?",
                  description:
                    "We should be your number one choice in the Merrimack Valley, southern New Hampshire and northern Massachusetts for Vinyl Siding, Home Additions, Kitchen and Bathroom Remodeling, Flooring, Decking, Window Replacements and more. Gelinas Siding & Contracting has been serving home owners in southern NH and northern MA since 1980. We customize your home’s interior and exterior with a variety of beautiful and durable products expertly installed.",
                  link: "3",
                },
              ]}
            />
          </div>
        </section>
        <section className="w-full h-[80vh] my-10 flex flex-col justify-center px-5 ">
          <div className="flex w-full lg:max-w-screen-xl mx-auto">
            <h2 className="w-full px-4 lg:px-0 text-xl font-bold tracking-tighter sm:text-2xl md:text-3xl flex items-end">
              Recent Projects
            </h2>
            <button
              onClick={prevCard}
              type="button"
              className="w-fit self-start bg-gray-800 text-white rounded-l-md border-r border-gray-100 py-2 hover:bg-[#3366ff] hover:text-white px-3"
            >
              <div className="flex flex-row align-middle">
                <svg
                  className="w-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </div>
            </button>
            <button
              onClick={nextCard}
              type="button"
              className="bg-gray-800 text-white rounded-r-md py-2 border-l border-gray-200 hover:bg-[#3366ff] hover:text-white px-3"
            >
              <div className="flex flex-row align-middle">
                <svg
                  className="w-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </div>
            </button>
          </div>
          <CardStack items={projectCards} />
        </section>
        <section className="bg-white flex flex-col py-4 mt-14 lg:mt-40 lg:p-10 dark:bg-gray-900 rounded-md">
          <div className="flex flex-wrap flex-col md:gap-4">
            <h2 className="w-full pt-4 px-4 lg:px-0 text-xl font-bold tracking-tighter sm:text-2xl md:text-3xl lg:max-w-screen-xl mx-auto">
              Testimonials
            </h2>
            <p className="text-gray-500 md:text-xl/relaxed lg:text-base/relaxed xl:text-xl/relaxed lg:px-0 dark:text-gray-400 lg:max-w-screen-xl mx-auto w-full px-4">
              See what our customers have to say about our work.
            </p>
          </div>
          <div className="flex justify-center items-center rounded-md antialiased dark:bg-grid-white/[0.05] relative overflow-hidden">
            <InfiniteMovingCards
              items={testimonials}
              direction="right"
              speed="slow"
            />
          </div>
        </section>
        <section
          className="bg-gray-200 py-6 mt-20 dark:bg-gray-800 rounded-md"
          id="contact"
        >
          <div className="lg:max-w-screen-xl grid gap-4 px-4 md:px-6 lg:gap-10 mx-auto">
            <div className="space-y-3">
              <h2 className="w-full pt-4 px-4 lg:px-0 text-xl font-bold tracking-tighter sm:text-2xl md:text-3xl lg:max-w-screen-xl mx-auto">
                Get a Quote
              </h2>
              <p className="text-gray-500 lg:px-0 md:text-xl/relaxed lg:text-base/relaxed xl:text-xl/relaxed dark:text-gray-400  lg:max-w-screen-xl mx-auto">
                Please leave any questions or comments below, and we will be
                sure to get back to you soon!
              </p>
            </div>
            <ContactForm />
          </div>
        </section>
      </div>
    </main>
  );
};

export default Body;
