"use client";

import React, { useCallback, useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider, Link } from "react-router-dom";

import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerDescription,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger,
} from "../components/ui/drawer";
import { ThemeProvider } from "../components/ui/theme-provider";

import Header from "./Header";
import Body from "./Body";
import Footer from "./Footer";

import "../js/app";

const siteURL = process.env.PRIMARY_SITE_URL;

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <div> home brokadidid</div>,
  },
]);

ReactDOM.createRoot(document.querySelector("#root")!).render(
  <React.StrictMode>
    <ThemeProvider defaultTheme="dark" storageKey="vite-ui-theme">
      <RouterProvider router={router} />
    </ThemeProvider>
  </React.StrictMode>
);

export type fetchTypes = {
  data?: {
    entry: {
      headline: string;
      subHead: string;
      heroImage: { img: string }[];
    };
    categories: {
      headline?: string;
      groupHandle: string;
      description?: string;
      heroImage?: { img: string }[];
      gallery?: { img: string }[];
      title: string;
    }[];
  };
};

// Here is where SSR would gain us value. Pull in JS libraries easily, utilize them, have the Server
// return HTML to quickly render while we are fetching data to hydrate said HTML.
/**
 * How to implement a React frontend with GraphQL. Currently not using Server Components. Yet...
 * @returns html that renders as the app
 */
function App() {
  const [response, setResponse] = useState<fetchTypes>({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    //   Vanilla Node.js implementation of generic 'ping' test in craftcms
    // data?.data?.ping
    fetch(siteURL + "/api", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // "Authorization": "Bearer somethingsomethingsomething", // Get auth token from craftcms admin panel
      },
      body: JSON.stringify({
        query: `{
                  entry {
                    ... on home_home_Entry {
                        headline
                        subHead
                       heroImage {
        img
      }
                      darkModeImage {
        img
      }
                      }
                    }
                  categories {
                       groupHandle

                    ... on services_Category {
                        headline
                        description
                        heroImage{
                          img
                          url
                        }
                        title
                       }
                    ... on testimonial_Category {
                        title
                        description
                        subHead
                       }
                    ... on projects_Category {
                      title
                        gallery {
                          img
                        }
                      }
                    }
                }`,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        setLoading(false);
        setResponse(res);
      });
  }, []);

  if (loading) {
    return <div>loading</div>;
  }

  return (
    <div className="flex flex-col my-0 bg-white dark:bg-gray-800">
    
      <div className="">
        <div className="min-h-screen">
          <Header />
          <Body response={response} />
          <Footer />
        </div>
      </div>
    </div>
  );
}
