"use client";
import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { cn } from "../utils/cn";

type Card = {
  id: number;
  content: JSX.Element | React.ReactNode | string;
  className: string;
  thumbnail: string;
  title: string;
};

export const LayoutGrid = ({ cards }: { cards: Card[] }) => {
  const [selected, setSelected] = useState<Card | null>(null);
  const [lastSelected, setLastSelected] = useState<Card | null>(null);

  const handleClick = (card: Card) => {
    setLastSelected(selected);
    setSelected(card);
  };

  const handleOutsideClick = () => {
    setLastSelected(selected);
    setSelected(null);
  };

  return (
    <div className="w-full h-full p-4 grid grid-cols-1 md:grid-cols-4  max-w-7xl mx-auto gap-4 lg:px-0">
      {cards.map((card, i) => (
        <div key={i} className={cn(card.className, "")}>
          <motion.div
            onClick={() => handleClick(card)}
            className={cn(
              card.className,
              "relative overflow-hidden cursor-pointer",
              selected?.id === card.id
                ? "rounded-lg cursor-pointer absolute inset-0 h-3/4 w-full md:w-1/2 m-auto z-50 flex justify-center items-center flex-wrap flex-col"
                : lastSelected?.id === card.id
                ? "z-40 bg-white rounded-xl h-full w-full"
                : "bg-white rounded-xl h-full w-full"
            )}
            layout
          >
            {selected?.id === card.id && (
              <div className="bg-transparent h-full w-full flex flex-col justify-end rounded-lg shadow-2xl relative z-[60]">
                <motion.div
                  initial={{
                    opacity: 0,
                  }}
                  animate={{
                    opacity: 0.6,
                  }}
                  className="absolute inset-0 h-full w-full bg-black opacity-60 z-10"
                />
                <motion.div
                  initial={{
                    opacity: 0,
                    y: 100,
                  }}
                  animate={{
                    opacity: 1,
                    y: 0,
                  }}
                  transition={{
                    duration: 0.3,
                    ease: "easeInOut",
                  }}
                  className="relative px-8 pb-4 z-[70] overflow-auto mt-5"
                >
                  {selected?.content}
                  <a
                    className="mt-6 bg-white text-gray-800 font-bold rounded border-b-2 border-[#3366ff] hover:border-[#223dbf] hover:bg-[#3366ff] hover:text-white hover:tracking-widest hover:px-4 transition-all shadow-md py-2 px-6 inline-flex items-center"
                    href="#contact"
                    onClick={handleOutsideClick}
                  >
                    Get in touch
                  </a>
                  {/* <div
                    className="mt-6 ml-3 bg-transparent text-white font-bold rounded bg-gray-900 border-2 border-solid border-[#3366ff] hover:border-[#223dbf] hover:bg-[#3366ff] hover:text-white transition-all shadow-md py-2 px-6 inline-flex items-center"
                    onClick={handleOutsideClick}
                  >
                    Close
                  </div> */}
                </motion.div>
              </div>
            )}
            {selected?.id === card.id ? (
              <img
                src={card.thumbnail}
                height="500"
                width="500"
                className={
                  "blur-none object-cover absolute inset-0 h-full w-full transition duration-200"
                }
                alt="thumbnail"
              />
            ) : (
              <BlurImage card={card} />
            )}
          </motion.div>
        </div>
      ))}
      <motion.div
        onClick={handleOutsideClick}
        className={cn(
          "absolute h-full w-screen left-0 top-0 bg-black opacity-0 z-10",
          selected?.id ? "pointer-events-auto" : "pointer-events-none"
        )}
        animate={{ opacity: selected?.id ? 0.3 : 0 }}
      />
    </div>
  );
};

const BlurImage = ({ card }: { card: Card }) => {
  return (
    <div className="relative h-full">
      <h3 className="text-2xl top-1/2 bg-gray-800/50 w-full text-center content-center z-10 text-white relative">
        {card.title}
      </h3>
      <img
        src={card?.thumbnail || "../../../web/assets/images.png"}
        height="500"
        width="500"
        className={
          "blur-none object-cover absolute inset-0 h-full w-full transition duration-200"
        }
        alt="thumbnail"
      />
    </div>
  );
};
