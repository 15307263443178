"use client";
import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { Card } from "../Body";

export const CardStack = ({
  items,
  offset,
  scaleFactor,
}: {
  items: Card[];
  offset?: number;
  scaleFactor?: number;
}) => {
  const CARD_OFFSET = offset || 10;
  const SCALE_FACTOR = scaleFactor || 0.06;

  return (
    <div className="lg:max-w-screen-xl mx-auto relative h-[80vh] w-full">
      <div className="relative h-full w-full mt-24">
        {items.map((card, index) => {
          return (
            <motion.div
              key={card.id}
              className={`card-stack-card absolute w-full h-full  dark:bg-gray-900 bg-white top-0 bottom-[80%] rounded-3xl p-4 shadow-xl border border-neutral-200 dark:border-white/[0.1]  shadow-black/[0.1] dark:shadow-white/[0.05] flex flex-col`}
              style={{
                transformOrigin: "top center",
              }}
              animate={{
                top: index * -CARD_OFFSET,
                scale: 1 - index * SCALE_FACTOR, // decrease scale for cards that are behind
                zIndex: items.length - index, //  decrease z-index for the cards that are behind
              }}
            >
              <h3 className="text-neutral-800 font-medium md:text-xl mb-4 dark:text-white">
                {card.name}
              </h3>
              <div
                className={`h-full w-full flex md:items-center ${
                  index === 0 ? "overflow-auto" : "overflow-hidden"
                }`}
              >
                {card.content}
              </div>
            </motion.div>
          );
        })}
      </div>
    </div>
  );
};
